<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <h1>Sales Progress</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <select class="form-control" v-model="monthFocus">
                        <option v-for="[month, label] in months" :value="month" :key="month">{{ label }}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <select class="form-control" v-model="yearFocus">
                        <option  v-for="[year, label] in lastSevenYears" :value="year" :key="year">{{ label }}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-4">
                <button class="btn btn-block btn-primary" @click="loadDashboard">Load Dashboard</button>
            </div>
            <div class="col-sm-12 margin_bottom">
                <ul class="nav nav-tabs nav-justified">
                    <li @click="cdiLocationFocus = location_id" :class="(cdiLocationFocus == location_id) ? 'active' : ''" v-for="[location_id, location] in cdiLocationOptions" :key="location_id"><a>{{ location }}</a></li>
                </ul>
            </div>
        </div>
        <div v-show="!loadingChart">
            <div class="row" v-if="this.dashboardData">
                <div class="col-sm-4">
                    <Sales v-if="chartData" :sales="chartData[cdiLocationFocus].incoming" />
                </div>
                <div class="col-sm-4">
                    <Sales v-if="chartData" :sales="chartData[cdiLocationFocus].outgoing"/>
                </div>
                <div class="col-sm-4">
                    <div class="panel panel-default">
                        <div class="panel-heading panel-title text-center">
                            <h4>{{ cache.cdiLocationCache[cdiLocationFocus] }}</h4>
                        </div>
                        <div class="panel-body text-center">
                            <p class="dashboard_md_text">Daily Goal: ${{ Math.round(dashboardData.location_data[cdiLocationFocus].day_goal).toLocaleString() }}</p>
                            <p class="dashboard_md_text">Work Days: {{ dashboardData.business_day_count }}</p>
                            <p class="dashboard_md_text">Month Goal: ${{ Math.round(dashboardData.location_data[cdiLocationFocus].location_goal).toLocaleString() }}</p>
                        </div>
                    </div>

                </div>
            </div>


            <div class="row" v-if="this.dashboardData">
                <div class="col-sm-12">
                    <table class="table table-responsive table-condensed">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th class="number">Daily Sales</th>
                            <th class="number">Sales/MTD</th>
                            <th class="number">Goal/MTD</th>
                            <th class="number">MTD Daily Avg.</th>
                            <th style="min-width: 25%">MTD Sales/MTD Goal</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(day, count) in dashboardData.location_data[cdiLocationFocus].days" :key="count">
                            <td>{{ day.actual_ship }}</td>
                            <td class="number">${{ Math.round(day.daily_total).toLocaleString() }}</td>
                            <td class="number">${{ Math.round(day.cumulative_total).toLocaleString() }}</td>
                            <td class="number">${{ Math.round(day.cumulative_goal).toLocaleString() }}</td>
                            <td class="number">${{ Math.round(day.mtd_daily_average).toLocaleString() }}</td>
                            <td style="padding: 0px">
                                <ProgressBar
                                    :title="'Day Goal Progress'"
                                    :textColor="'#e9e9e9'"
                                    :height="25"
                                    :max="Math.round(day.cumulative_goal)"
                                    :value="Math.round(day.cumulative_total)"
                                    :display-opts="{
                                    showTitle: false,
                                    showValue: false,
                                    showMax: false,
                                    showPercentage: true
                                }"
                                    :financialFormat="false"
                                    :color="(day.cumulative_total >= day.cumulative_goal) ? '#00bf0d' : '#bf0000'"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-show="loadingChart">
            <LoadingIcon :active="!chartData" />
        </div>
    </div>
</template>


<script>

import ProgressBar from "@/components/reports/utils/ProgressBar";
import cdiLocationCache from "@/cache/cdiLocation.cache";
import Sales from "@/components/my/dashboards/panels/Sales";
import { store } from '@/store/Report.store.js';
import LoadingIcon from '@/components/utils/LoadingIcon';
const _ = require('lodash');

export default {
    components: {
        ProgressBar,
        Sales,
        LoadingIcon
    },
    data() {
        return {
            state: store.state,
            cdiLocationFocus: 0,
            monthFocus: (new Date().getMonth() + 1),
            yearFocus: new Date().getFullYear(),
            dashboardData: null,
            chartData: null,
            cache: {
                cdiLocationCache
            },
            loadingChart: false,
        }
    },
    created() {
        this.$appStore.setTitle(['Sales Progress']);
        this.state.showNav = false;
        this.loadDashboard();
        this.loadChart();
    },
    methods: {
        loadDashboard: function() {
            let local = this;
            store.customReport('daily_invoiced', 'get_result', {
                'params[month]': this.monthFocus,
                'params[year]': this.yearFocus,
            }).then((response) => {
                local.dashboardData = response;
                this.loadChart();
            })
        },

        loadChart() {
            let local = this;
            this.loadingChart = true
            store.customReport('daily_invoiced', 'get_sales_chart_data', {
                'params[month]': this.monthFocus,
                'params[year]': this.yearFocus
            }).then((response) => {
                local.chartData = [];
                for (let location_id in response) {
                    local.chartData[location_id] = [];
                    local.chartData[location_id].incoming = response[location_id].incoming
                    local.chartData[location_id].outgoing = response[location_id].outgoing;
                }
                local.loadingChart = false;
            });

        }


    },
    computed: {
        lastSevenYears: function () {
            let years = [];
            let currentYear = new Date().getFullYear();
            for (let i = 0; i < 7; i++) {
                years.push([currentYear - i, currentYear - i]);
            }
            return years.reverse()
        },
        months: function () {
            return [
                [1, 'January'],
                [2, 'February'],
                [3, 'March'],
                [4, 'April'],
                [5, 'May'],
                [6, 'June'],
                [7, 'July'],
                [8, 'August'],
                [9, 'September'],
                [10, 'October'],
                [11, 'November'],
                [12, 'December']
            ]
        },
        cdiLocationOptions: function () {
            let locations = _.cloneDeep(this.cache.cdiLocationCache.CDI_LOCATIONS);
            locations.unshift([0, 'CDI']);
            return locations;
        },
    },
    mounted() {
        this.cdiLocationFocus = 0;
    },
    destroyed(){
        this.state.showNav = true;
    }
}

</script>
